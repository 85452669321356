import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom"
import { Input, NumberInput, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Button } from "components/Common/Button";
// import { Dropzone } from "components/Common/Dropzone"
// import PhoneInputField from "components/Common/PhoneInput/PhoneInput"
// import { editAdmin, getAdmin } from "services/apis/admin"
// import { useNotification } from "context"
import { DownArrowSVG } from "assets/icons";
// import { checkAuth } from "utils/helper"
// import {
//   useAddAdminMutation,
//   useUpdateAdminMutation,
// } from "hooks/Admin/adminAPI"
import { Header } from "components/Common/Header";
import { Dropzone } from "components/Common/Dropzone";
import {
  useAddBankDetailsMutation,
  useGetBankDetailsQuery,
  useUpdateBankDetailsMutation,
} from "hooks/Admin/bankDetailsAPI";
import { getStorageItem } from "services/storage";

type BankDetailsType = {
  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  branchName: string;
  ifscCode: string;
  upiAddress?: string;
  cancelledCheque?: string;
  settlementType: string;
  isBusiness?: boolean;
  buyerFinderFees?: any;
  withholdingAmount?: any;
  paymentWindow?: any;
  paymentSettlementBasis?: any;
  tax_number?: any;
};

const KeyManagement = () => {
  // const [adminPhoto, setAdminPhoto] = useState("")
  const [cancelledCheque, setCancelledCheque] = useState<any>("");
  const [settlementType, setSettlementType] = useState("");
  const [paymentSettlementBasis, setPaymentSettlementBasis] = useState("");
  const [personalId, setPersonalId] = useState("");
  const [cancelledChequeValidationText, setCancelledChequeValidationText] =
    useState("");
  // const navigate = useNavigate()
  const bankDetailsForm = useForm({
    validateInputOnChange: true,
    initialValues: {
      upiAddress: "",
      accountHolder: "",
      accountNumber: "",
      bankName: "",
      branchName: "",
      ifsc: "",
      buyerFinderFees: "",
      withholdingAmount: 0,
      paymentWindow: 0,
      tax_number: "",
    },
    validate: {
      accountHolder: (value) => (value?.length === 0 ? "Required" : null),
      accountNumber: (value) => (value?.length === 0 ? "Required" : null),
      bankName: (value) => (value?.length === 0 ? "Required" : null),
      branchName: (value) => (value?.length === 0 ? "Required" : null),
      ifsc: (value) => (value?.length === 0 ? "Required" : null),
      buyerFinderFees: (value) => (value?.length === 0 ? "Required" : null),
      withholdingAmount: (value) =>
        value === 0 && getStorageItem("adminType") === "e-commerce-b2b"
          ? "Required"
          : null,
      paymentWindow: (value) =>
        value === 0 && getStorageItem("adminType") === "e-commerce-b2b"
          ? "Required"
          : null,
    },
  });
  // const { setText } = useNotification()

  const [updateBankDetails] = useUpdateBankDetailsMutation();

  const [addBankDetails] = useAddBankDetailsMutation();

  const { data: bankDetails } = useGetBankDetailsQuery();

  useEffect(() => {
    const personalData = bankDetails?.data;
    if (personalData) {
      setPersonalId(personalData?._id);
      setCancelledCheque(personalData?.cancelledCheque);
      setSettlementType(personalData?.settlementType);
      setPaymentSettlementBasis(personalData?.paymentSettlementBasis);
      bankDetailsForm.setValues({
        upiAddress: personalData?.upiAddress || "",
        accountHolder: personalData?.accountHolderName,
        accountNumber: personalData?.accountNumber,
        bankName: personalData?.bankName,
        branchName: personalData?.branchName,
        ifsc: personalData?.ifscCode,
        tax_number: personalData?.tax_number,
        buyerFinderFees: personalData?.buyerFinderFees?.fee_amount,
        paymentWindow: Number(personalData?.paymentWindow?.split("")[1]),
        withholdingAmount: Number(personalData?.withholdingAmount),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankDetails]);

  async function handleAddBankDetails() {
    let payload: BankDetailsType = {
      accountHolderName: bankDetailsForm.values.accountHolder,
      accountNumber: bankDetailsForm.values.accountNumber,
      bankName: bankDetailsForm.values.bankName,
      branchName: bankDetailsForm.values.branchName,
      ifscCode: bankDetailsForm.values.ifsc,
      upiAddress: "",
      cancelledCheque: cancelledCheque._id,
      settlementType: settlementType,
      isBusiness: false,
      buyerFinderFees: bankDetailsForm.values.buyerFinderFees,
      withholdingAmount: "",
      paymentWindow: "",
      paymentSettlementBasis: "",
      tax_number: bankDetailsForm.values.tax_number,
    };
    if (settlementType === "UPI") {
      payload = {
        ...payload,
        upiAddress: bankDetailsForm.values.upiAddress,
      };
    }
    if (getStorageItem("adminType") === "e-commerce-b2b") {
      payload = {
        ...payload,
        isBusiness: true,
        withholdingAmount: bankDetailsForm.values.withholdingAmount,
        paymentWindow: `P${bankDetailsForm.values.paymentWindow}D`,
        paymentSettlementBasis: paymentSettlementBasis,
      };
    }
    const response = await addBankDetails(payload);
    if (response?.data?.success) {
      console.log("ADDED");
    }
  }

  async function handleEditBankDetails() {
    const id = personalId;
    let payload: BankDetailsType = {
      accountHolderName: bankDetailsForm.values.accountHolder,
      accountNumber: bankDetailsForm.values.accountNumber,
      bankName: bankDetailsForm.values.bankName,
      branchName: bankDetailsForm.values.branchName,
      ifscCode: bankDetailsForm.values.ifsc,
      cancelledCheque: cancelledCheque._id,
      settlementType: settlementType,
      buyerFinderFees: Number(bankDetailsForm.values.buyerFinderFees),
      tax_number: bankDetailsForm.values.tax_number,
    };

    if (settlementType === "UPI") {
      payload = {
        ...payload,
        upiAddress: bankDetailsForm.values.upiAddress,
      };
    }
    if (getStorageItem("adminType") === "e-commerce-b2b") {
      payload = {
        ...payload,
        isBusiness: true,
        withholdingAmount: Number(bankDetailsForm.values.withholdingAmount),
        paymentWindow: `P${bankDetailsForm.values.paymentWindow}D`,
        paymentSettlementBasis: paymentSettlementBasis,
      };
    }
    const response = await updateBankDetails({
      id,
      payload,
    });
    if (response?.data?.success) {
      console.log("EDITED");
      window.location.reload();
    }
  }

  function handleBasicDetailsSubmit() {
    if (bankDetails?.data) {
      handleEditBankDetails();
    } else {
      handleAddBankDetails();
    }
  }

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main bg-white flex-1">
        <div className="section-header">Bank Details</div>
        {/* <div className="pg-subheader h-40 flex justify-stretch align-center mb-30 mx-80"> */}
        <section className="min-h-70 flex-col bg-white my-5 mx-80 mt-30">
          <form onSubmit={bankDetailsForm.onSubmit(handleBasicDetailsSubmit)}>
            {/* <p className="txt-16 mb-20">Bank Details</p> */}
            <div className="grid grid-cols-3 col-gap-40 row-gap-25">
              <Input.Wrapper
                classNames={{
                  label: "clr-grey txt-12 txtw-4",
                }}
                label="SETTLEMENT TYPE"
                required
              >
                <Select
                  variant="filled"
                  placeholder="Select settlement type"
                  value={settlementType}
                  onChange={(val: string) => setSettlementType(val)}
                  data={["NEFT", "RTGS", "UPI"]}
                  rightSection={<DownArrowSVG />}
                />
              </Input.Wrapper>
              <Input.Wrapper
                classNames={{
                  label: "clr-grey txt-12 txtw-4",
                }}
                label="UPI ADDRESS"
                // required
              >
                <TextInput
                  classNames={{
                    wrapper: "clr-grey txt-12 txtw-4",
                  }}
                  placeholder="Enter UPI"
                  variant="filled"
                  disabled={settlementType !== "UPI"}
                  {...bankDetailsForm.getInputProps("upiAddress")}
                />
              </Input.Wrapper>
              <Input.Wrapper
                classNames={{
                  label: "clr-grey txt-12 txtw-4",
                }}
                label="ACCOUNT HOLDER NAME"
                required
              >
                <TextInput
                  placeholder="Enter Account Holder Name"
                  variant="filled"
                  {...bankDetailsForm.getInputProps("accountHolder")}
                />
              </Input.Wrapper>
              <Input.Wrapper
                classNames={{
                  label: "clr-grey txt-12 txtw-4",
                }}
                label="ACCOUNT NUMBER"
                required
              >
                <TextInput
                  placeholder="Enter Account Number"
                  variant="filled"
                  {...bankDetailsForm.getInputProps("accountNumber")}
                />
              </Input.Wrapper>

              <Input.Wrapper
                classNames={{
                  label: "clr-grey txt-12 txtw-4",
                }}
                label="BANK NAME"
                required
              >
                <TextInput
                  placeholder="Enter Bank Name"
                  variant="filled"
                  {...bankDetailsForm.getInputProps("bankName")}
                />
              </Input.Wrapper>

              <Input.Wrapper
                classNames={{
                  label: "clr-grey txt-12 txtw-4",
                }}
                label="BRANCH NAME"
                required
              >
                <TextInput
                  placeholder="Enter Branch Name"
                  variant="filled"
                  {...bankDetailsForm.getInputProps("branchName")}
                />
              </Input.Wrapper>
              <Input.Wrapper
                classNames={{
                  label: "clr-grey txt-12 txtw-4",
                }}
                label="IFSC CODE"
                required
              >
                <TextInput
                  placeholder="Enter IFSC Code"
                  variant="filled"
                  {...bankDetailsForm.getInputProps("ifsc")}
                />
              </Input.Wrapper>
              <Input.Wrapper
                classNames={{
                  label: "clr-grey txt-12 txtw-4",
                }}
                label="GST"
                required
              >
                <TextInput
                  placeholder="Enter GST Number"
                  variant="filled"
                  readOnly
                  {...bankDetailsForm.getInputProps("tax_number")}
                />
              </Input.Wrapper>

              <div>
                <span style={{ color: "#667085", fontSize: "12px" }}>
                  CANCELLED CHEQUE
                </span>
                <Dropzone
                  mediaTypes={["image/png", "image/jpeg", "image/svg+xml"]}
                  media={cancelledCheque?.link}
                  setMedia={(img) => {
                    setCancelledCheque(img[0]);
                  }}
                  mediaSize={2000000}
                  mediaValidationText={cancelledChequeValidationText}
                  setMediaValidationText={setCancelledChequeValidationText}
                />
              </div>
            </div>
            {/* <div className="mt-30 w-fit-content"> */}

            {/* </div> */}
            <p className="mr-10 txt-16 txtw-6 clr-grey">Buyer NP Information</p>
            <br />
            <div className="grid grid-cols-3 col-gap-40 row-gap-25">
              <Input.Wrapper
                classNames={{
                  label: "clr-grey txt-12 txtw-4",
                }}
                label="BUYER FINDER FEES"
                required
              >
                <TextInput
                  placeholder="Enter Fee"
                  variant="filled"
                  {...bankDetailsForm.getInputProps("buyerFinderFees")}
                />
              </Input.Wrapper>
              {getStorageItem("adminType") === "e-commerce-b2b" && (
                <>
                  <Input.Wrapper
                    classNames={{
                      label: "clr-grey txt-12 txtw-4",
                    }}
                    label="WITHHOLDING AMOUNT"
                    required
                  >
                    <TextInput
                      placeholder="Enter Amount"
                      variant="filled"
                      {...bankDetailsForm.getInputProps("withholdingAmount")}
                    />
                  </Input.Wrapper>
                  <Input.Wrapper
                    classNames={{
                      label: "clr-grey txt-12 txtw-4",
                    }}
                    label="PAYMENT WINDOW"
                    required
                  >
                    <NumberInput
                      placeholder="Select Day(s)"
                      variant="filled"
                      min={0}
                      max={30}
                      {...bankDetailsForm.getInputProps("paymentWindow")}
                    />
                  </Input.Wrapper>
                  <Input.Wrapper
                    classNames={{
                      label: "clr-grey txt-12 txtw-4",
                    }}
                    label="PAYMENT SETTLEMENT BASIS"
                    required
                  >
                    <Select
                      variant="filled"
                      placeholder="Select settlement basis"
                      value={paymentSettlementBasis}
                      onChange={(val: any) => {
                        setPaymentSettlementBasis(val);
                      }}
                      data={[
                        { value: "delivery", label: "Delivery" },
                        {
                          value: "return_window_expiry",
                          label: "Return Expiry",
                        },
                        { value: "shipment", label: "Shipment" },
                      ]}
                      rightSection={<DownArrowSVG />}
                    />
                  </Input.Wrapper>
                </>
              )}
            </div>
            <div className="flex justify-end  mt-10 pt-10">
              <Button
                type="filled-primary"
                text={bankDetails?.data ? "Update" : "Save"}
                onClick={() => {}}
              />
            </div>
          </form>
        </section>
        {/* </div> */}
      </main>
    </div>
  );
};

export default KeyManagement;
