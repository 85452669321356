import {
  Accordion,
  ActionIcon,
  Input,
  Modal,
  TextInput,
  Textarea,
} from "@mantine/core";
import { NoOffersSVG, PencilIconSVG, TrashboxIconSVG } from "assets/icons";
import { Button } from "components/Common/Button";
import { DeleteModal } from "components/Common/DeleteModal";
import { EmptyPage } from "components/Common/EmptyPage";
import {
  useAddFAQMutation,
  useDeleteFAQMutation,
  useGetFAQQuery,
  useUpdateFAQMutation,
} from "hooks/Admin/buyerAppInfo";
import { useEffect, useState } from "react";
import { getStorageItem } from "services/storage";

type QuestionnaireType = { question: string; answer: string; _id?: string };

function FAQSection() {
  const initialFAQ = {
    question: "",
    answer: "",
  };
  const { data: FAQData, refetch } = useGetFAQQuery();
  const [addFAQ] = useAddFAQMutation();
  const [updateFAQ] = useUpdateFAQMutation();
  const [deleteFAQ] = useDeleteFAQMutation();

  const FAQLinkFromBE = FAQData?.data?.find((item: any) => item.link)?.link;

  const [showAddEditFAQ, setShowAddEditFAQ] = useState<boolean>(false);
  const [FAQLink, setFAQLink] = useState<string>("");

  const [isQuestionnaireThere, setIsQuestionnaireThere] =
    useState<boolean>(false);
  const [showQuestionnaire, setShowQuestionnaire] = useState<boolean>(false);
  const [FAQQuestionnaire, setFAQQuestionnaire] =
    useState<QuestionnaireType>(initialFAQ);
  const [toBeDeletedFAQ, setToBeDeletedFAQ] = useState<any>(null);
  const [isRemoveFAQModalVisible, setRemoveFAQModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (FAQData?.data?.find((item: any) => item.question))
      setIsQuestionnaireThere(true);
    setFAQLink(FAQLinkFromBE);
  }, [FAQData, FAQLinkFromBE]);

  const showEditFAQ = (FAQ: QuestionnaireType) => {
    setShowAddEditFAQ(true);
    const toEdit = FAQData?.data?.find((item: any) => item._id === FAQ._id);
    setFAQQuestionnaire({
      question: toEdit.question,
      answer: toEdit.answer,
      _id: toEdit._id,
    });
  };

  const onDeleteFAQ = (data: any) => () => {
    setRemoveFAQModalVisible(true);
    setToBeDeletedFAQ(data._id);
  };

  async function handleRemoveFAQ() {
    const response = await deleteFAQ(toBeDeletedFAQ);
    if (response?.data?.success) {
      refetch();
      setRemoveFAQModalVisible(false);
      setToBeDeletedFAQ(null);
    }
  }

  const handleAddOrUpdateFAQ = async () => {
    const { _id, question, answer } = FAQQuestionnaire;
    let APISuccessful;
    if (_id) {
      let data = {
        query: {
          _id,
        },
        payload: {
          question,
          answer,
          isBusiness:
            getStorageItem("adminType") === "e-commerce-b2b" ? true : false,
        },
      };
      APISuccessful = await updateFAQ(data);
    } else {
      let payload = {
        ...FAQQuestionnaire,
        isBusiness:
          getStorageItem("adminType") === "e-commerce-b2b" ? true : false,
      };
      APISuccessful = await addFAQ(payload);
    }

    if (APISuccessful) {
      refetch();
      setShowAddEditFAQ(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddOrUpdateLink = () => {
    if (!FAQLinkFromBE) {
      addFAQ({ link: FAQLink });
    } else {
      let payload = {
        link: FAQLink,
        isBusiness:
          getStorageItem("adminType") === "e-commerce-b2b" ? true : false,
      };
      let id = FAQData?.data?.find((item: any) => item.link)?._id;

      let data = {
        query: {
          _id: id,
        },
        payload: payload,
      };
      updateFAQ(data);
    }
  };

  return (
    <div className="mt-30 w-500">
      <div className="w-full flex justify-stretch align-center mb-20">
        <Button
          type="subtle"
          text={
            showQuestionnaire
              ? FAQLinkFromBE
                ? "Edit Link to FAQ"
                : "Add Link to FAQ"
              : isQuestionnaireThere
              ? "Edit Questionnaire for FAQ"
              : "Add Questionnaire for FAQ"
          }
          loading={false}
          onClick={() => setShowQuestionnaire(!showQuestionnaire)}
        />

        {showQuestionnaire && (
          <Button
            type="filled-primary"
            text="Add a question"
            loading={false}
            onClick={() => {
              setFAQQuestionnaire(initialFAQ);
              setShowAddEditFAQ(true);
            }}
          />
        )}
      </div>

      {FAQData?.data ? (
        showQuestionnaire ? (
          <>
            {showAddEditFAQ && (
              <Modal
                opened={showAddEditFAQ}
                title={FAQQuestionnaire._id ? "Edit FAQ" : "Add FAQ"}
                onClose={() => setShowAddEditFAQ(false)}
              >
                <Input.Wrapper
                  className="mt-30"
                  withAsterisk
                  classNames={{
                    label: "clr-grey txt-12 txtw-4",
                  }}
                  label="FAQ Question"
                  required
                >
                  <TextInput
                    placeholder="Enter Question"
                    variant="filled"
                    value={FAQQuestionnaire.question}
                    onChange={(e) =>
                      setFAQQuestionnaire((prev) => ({
                        ...prev,
                        question: e.target.value,
                      }))
                    }
                  />
                </Input.Wrapper>
                <Input.Wrapper
                  className="my-30"
                  withAsterisk
                  classNames={{
                    label: "clr-grey txt-12 txtw-4",
                  }}
                  label="FAQ Answer"
                  required
                >
                  <Textarea
                    classNames={{ input: "min-h-100" }}
                    placeholder="Enter Answer"
                    variant="filled"
                    value={FAQQuestionnaire.answer}
                    onChange={(e) =>
                      setFAQQuestionnaire((prev) => ({
                        ...prev,
                        answer: e.target.value,
                      }))
                    }
                  />
                </Input.Wrapper>
                <Button
                  type="filled-primary"
                  text="Save Changes"
                  loading={false}
                  onClick={handleAddOrUpdateFAQ}
                />
              </Modal>
            )}
            <div>
              <Accordion>
                {FAQData?.data
                  ?.filter((item: any) => item.question)
                  ?.map((item: any) => (
                    <div key={item._id} className="flex justify-stretch">
                      <Accordion.Item
                        key={item._id}
                        value={item.question}
                        className="w-500"
                      >
                        <Accordion.Control>
                          <div className="flex justify-stretch">
                            {item.question}{" "}
                            <div style={{ display: "flex" }}>
                              <ActionIcon onClick={() => showEditFAQ(item)}>
                                <PencilIconSVG />
                              </ActionIcon>
                              <ActionIcon onClick={onDeleteFAQ(item)}>
                                <TrashboxIconSVG />
                              </ActionIcon>
                            </div>
                          </div>
                        </Accordion.Control>
                        <Accordion.Panel>{item.answer}</Accordion.Panel>
                      </Accordion.Item>
                    </div>
                  ))}
              </Accordion>
            </div>
          </>
        ) : (
          <>
            {/* <Input.Wrapper
              className="w-500 mt-20"
              withAsterisk
              classNames={{
                label: "clr-grey txt-12 txtw-4",
              }}
              label="FAQ"
              required
            >
              <TextInput
                placeholder="Enter Link"
                variant="filled"
                defaultValue={FAQLinkFromBE}
                onChange={(e) => setFAQLink(e.target.value)}
              />
            </Input.Wrapper>

            <div className="flex justify-end mt-10 pt-10 w-500">
              <Button
                type="filled-primary"
                text={FAQLinkFromBE ? `Save Changes` : `Save Link`}
                disabled={FAQLinkFromBE === FAQLink}
                loading={false}
                onClick={handleAddOrUpdateLink}
              />
            </div> */}
          </>
        )
      ) : (
        <EmptyPage img={NoOffersSVG} item="Details" />
      )}
      <DeleteModal
        isDeleteModalOpen={isRemoveFAQModalVisible}
        closeDeleteModal={() => setRemoveFAQModalVisible(false)}
        data={{
          text: "question",
        }}
        onConfirm={handleRemoveFAQ}
      />
    </div>
  );
}

export default FAQSection;
