import { useEffect, useState } from "react";
import { Radio } from "@mantine/core";
import { Button } from "components/Common/Button";
import { Modal } from "components/Common/Modal";

import { INITIAL_SORT_BY } from "constants/index";
import { updateIssuesStatusFilter } from "utils/helper";
import { useSortBy } from "context";
import { constant } from "./constant";
import { getStorageItem, setStorageItem } from "services/storage";

const SortBy = ({
  isSortByModalVisible,
  type,
  onSortChange,
  setSortCount,
}: {
  isSortByModalVisible: boolean;
  type: string;
  onSortChange: (val: string) => void;
  setSortCount: any;
}) => {
  const [currentFilterSelected, setCurrentFilterSelected] = useState("");
  const [ordersFilters, setOrdersFilters] = useState<any>(INITIAL_SORT_BY);
  const { setIsSortByModalVisible } = useSortBy();

  useEffect(() => {
    if (!isSortByModalVisible) {
      updateOrdersFilter("reset");
    }
  }, [isSortByModalVisible, updateOrdersFilter]);

  useEffect(() => {
    if (type) {
      let data = getStorageItem("sortBy");

      if (data && data[type]) {
        const defaultSelected = Object.keys(data[type])
          .toString()
          .concat("-", Object.values(data[type]).toString());
        console.log(defaultSelected);
        setOrdersFilters(data[type]);
        setCurrentFilterSelected(defaultSelected);
      }
    }
  }, [type]);
  console.log(currentFilterSelected, "fill");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function updateOrdersFilter(val: any) {
    const fil = val.split("-");

    setCurrentFilterSelected(val);
    let newOrdersFilters = {};
    switch (fil[0]) {
      case "title":
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "title"
        );

        break;
      case "firstName":
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "firstName"
        );

        break;
      case "lastName":
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "lastName"
        );

        break;
      case "email":
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "email"
        );

        break;
      case "role":
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "role"
        );

        break;
      case "updatedAt":
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "updatedAt"
        );

        break;
      case "totalRevenue":
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "totalRevenue"
        );

        break;
      case "totalOrders":
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "totalOrders"
        );

        break;
      case "createdAt":
        console.log("fil[1]in");
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "createdAt"
        );
        console.log(newOrdersFilters, "orderFile");
        break;
      case "totalAmount":
        console.log(fil[1]);
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "totalAmount"
        );
        break;
      case "totalPrice":
        console.log(fil[1]);
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "totalPrice"
        );
        break;

      case "categoryName":
        console.log(fil[1]);
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "categoryName"
        );
        break;
      case "visible":
        console.log(fil[1]);
        newOrdersFilters = updateIssuesStatusFilter(
          newOrdersFilters,
          fil[1],
          "visible"
        );
        break;

      case "reset":
        console.log(newOrdersFilters, "ord");
        newOrdersFilters = {};
        // newOrdersFilters = resetOrdersFilter(newOrdersFilters)
        break;
      default:
        break;
    }
    console.log(newOrdersFilters, "nerOr");
    if (newOrdersFilters) {
      setSortCount(Object.keys(newOrdersFilters)?.length);
    } else {
      setSortCount(0);
    }
    setOrdersFilters({ ...newOrdersFilters });
  }

  function isValueInFilter(filter: string) {
    console.log(filter, "filinsort");
    const filters = filter.split("-");
    switch (filters[0]) {
      case "title":
        return `title-${ordersFilters["title"]?.toString()}`;
      case "lastName":
        return `lastName-${ordersFilters["lastName"]?.toString()}`;
      case "firstName":
        return `firstName-${ordersFilters["firstName"]?.toString()}`;
      case "email":
        return `email-${ordersFilters["email"]?.toString()}`;
      case "role":
        return `role-${ordersFilters["role"]?.toString()}`;
      case "totalRevenue":
        return `totalRevenue-${ordersFilters["totalRevenue"]?.toString()}`;
      case "totalOrders":
        return `totalOrders-${ordersFilters["totalOrders"]?.toString()}`;
      case "updatedAt":
        return `updatedAt-${ordersFilters["updatedAt"]?.toString()}`;
      case "createdAt":
        return `createdAt-${ordersFilters["createdAt"]?.toString()}`;
      case "totalAmount":
        return `totalAmount-${ordersFilters["totalAmount"]?.toString()}`;
      case "totalPrice":
        return `totalPrice-${ordersFilters["totalPrice"]?.toString()}`;
      case "categoryName":
        return `categoryName-${ordersFilters["categoryName"]?.toString()}`;
      case "visible":
        return `visible-${ordersFilters["visible"]?.toString()}`;
      default:
        break;
    }
  }

  return (
    <Modal
      title="Sort By"
      opened={isSortByModalVisible}
      onClose={() => setIsSortByModalVisible(false)}
      size={"md"}
    >
      <div className="flex align-start bd-btm pt-10 pb-10">
        <div className="flex justify-stretch  mb-15">
          <Radio.Group
            withAsterisk
            classNames={{
              root: "flex flex-col gcol-start-1 gcol-end-3 ",
              label: "txt-12 txtw-4 clr-grey txt-ucase",
            }}
            value={isValueInFilter(currentFilterSelected)}
            onChange={(e) => {
              console.log(e, "eve");

              updateOrdersFilter(e);
            }}
          >
            <div className="flex flex-col">
              {constant[type]?.map((status: any) => (
                <Radio
                  key={Math.random()}
                  value={status.value}
                  label={status.label}
                  className="mb-10"
                  classNames={{
                    label: "txt-12 txtw-4 clr-grey txt-cap",
                  }}
                />
              ))}
            </div>
          </Radio.Group>
        </div>
      </div>
      <div className="flex justify-stretch align-center pt-10">
        <Button
          type="default"
          text="Reset"
          onClick={() => {
            updateOrdersFilter("reset");
            let obj: any = ordersFilters;
            setStorageItem("sortBy", obj);
            // let data:string=generateUrlSearchParams(obj[`${type}`])
            onSortChange(obj[type]);
            setIsSortByModalVisible(false);
          }}
        />
        <Button
          type="filled-primary"
          text="Apply"
          onClick={() => {
            let obj: any = getStorageItem("sortBy") || {};
            obj[type] = ordersFilters;
            console.log(ordersFilters, "fil");
            setStorageItem("sortBy", obj);
            // let data:string=generateUrlSearchParams(obj[`${type}`])
            onSortChange(obj[type]);
            setIsSortByModalVisible(false);
          }}
        />
      </div>
    </Modal>
  );
};

export default SortBy;
