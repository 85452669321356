import { Header } from "components/Common/Header"
import getSymbolFromCurrency from "currency-symbol-map"
import { useGetMobilityRideDetailsQuery } from "hooks/Mobility/orderAPI"
import { useParams } from "react-router-dom"
import { getDisplayDate, getDisplayTime, rideStatusMap } from "utils/helper"
import CarImage from "assets/images/car_image.png"
import RoleStatusBadge from "components/Common/Badge/RoleStatus"
import { Timeline } from "@mantine/core"
import { ReactComponent as LocationPoint } from "assets/icons/LocationPoint.svg"
import { ReactComponent as PhonePe } from "assets/icons/Phonepe.svg"
import { ReactComponent as Visa } from "assets/icons/Visa.svg"
import styles from "./OrderDetails.module.scss"
import SupportTable from "./SupportTable"
import DetailsCard from "components/DetailsCard/DetailsCard"
import { EmptyPage } from "components/Common/EmptyPage"
import { LeftArrowSVG, NoOrdersSVG } from "assets/icons"
import { Button } from "components/Common/Button"

export default function MobilityRideDetails({
  rideId,
  goBack,
}: {
  rideId?: string
  goBack?: () => void
}) {
  const params = useParams()
  const orderId = rideId || params.id
  const { data: rideDetails } = useGetMobilityRideDetailsQuery(orderId)

  const {
    distanceTraveled,
    rider,
    driver,
    createdAt,
    fareBreakup,
    vehicle,
    rideStatus,
    endAddress,
    paymentMode,
    ridePay,
    startAddress,
    _id,
  } = rideDetails?.result ?? {}

  const address = { start: startAddress?.address, end: endAddress?.address }

  const currency = getSymbolFromCurrency(fareBreakup?.baseFare?.currency)

  // const bannerDetails = [
  //   rider?.name && { title: "Rider Name", value: rider?.name },
  //   _id && { title: "Ride ID", value: _id },
  //   createdAt && {
  //     title: "Booked At",
  //     value: `${getDisplayTime(createdAt)}, ${getDisplayDate(createdAt)}`,
  //   },
  // ].filter((item) => item)

  const cardDetails = [
    _id && {
      title: "ID",
      value: _id,
    },
    rider && { title: "Rider Name", value: rider?.name },
    distanceTraveled && {
      title: "Total Distance",
      value: distanceTraveled + " km",
    },
    currency &&
      ridePay && {
        title: "Total Amount",
        value: `${currency}${String(ridePay)}`,
      },
  ].filter((item) => item)

  const supportTicketsData = [
    {
      id: "2013721770jne1298948",
      title: "Driver cancelled the ride",
      status: "OPEN",
      created_at: "2024-03-27T12:49:20.82Z",
    },
    {
      id: "2013721770jne1298949",
      title: "Where's my driver?",
      status: "CLOSED",
      created_at: "2024-03-27T12:49:20.82Z",
    },
  ]

  const showFareBreakup = (status: any) => {
    if (
      status === "RIDE_CANCELLED" ||
      status === "RIDE_ENROUTE_PICKUP" ||
      status === "RIDE_ARRIVED_PICKUP" ||
      status === "RIDE_ASSIGNED"
    )
      return false
    else return true
  }

  console.log(showFareBreakup(rideStatus))
  const mappedRideStatus = rideStatusMap(rideStatus).toLowerCase()

  return (
    <div className="pg-container ">
      <Header />
      <main
        className="pg-main bg-white flex-1"
        style={{ height: "calc(100% - 66px)" }}
      >
        {!!rideDetails?.result ? (
          <div className="orderDetails flex flex-col gap-20">
            {goBack && (
              <Button
                type="subtle"
                text="Back"
                onClick={goBack}
                className="align-self-start"
                leftIcon={<LeftArrowSVG />}
              />
            )}
            <div className="txt-30 txtw-6">Ride Details</div>
            {/* <div className="detailsBanner">
              {bannerDetails.map(
                ({ title, value }: { title: string; value: string }) => (
                  <p className="txt-16 txtw-6 clr-grey-dark" key={title}>
                    {title}: {value}
                  </p>
                )
              )}
            </div> */}
            <div className="detailsCardContainer">
              {cardDetails.map(
                ({ title, value }: { title: string; value: string }) => (
                  <div className="detailsCard" key={title}>
                    <p className="description">{title}</p>
                    <p className="subHeading">{value}</p>
                  </div>
                )
              )}
            </div>
            <div className="flex gap-40 justify-center">
              <div>
                <div className={styles.card}>
                  <div className="flex justify-stretch align-center my-20">
                    <div className="flex gap-20 align-center">
                      <img src={CarImage} alt="car" />
                      <div className="txt-12 txtw-5 clr-grey">
                        {vehicle?.number && (
                          <p className="txt-14 clr-black">{vehicle?.number}</p>
                        )}
                        {vehicle?.make && vehicle?.model && (
                          <p>
                            {vehicle.make} {vehicle.model}
                          </p>
                        )}

                        {driver?.name && <p>{driver.name}</p>}
                      </div>
                    </div>
                    <div>
                      {rideStatus && (
                        <RoleStatusBadge type={mappedRideStatus} />
                      )}
                    </div>
                  </div>

                  {address.start && address.end && (
                    <Timeline
                      bulletSize={24}
                      lineWidth={2}
                      classNames={{
                        itemBullet: styles.bullet,
                        item: styles.line,
                      }}
                    >
                      <Timeline.Item
                        title="Pickup"
                        bullet={
                          <LocationPoint className={styles["bullet-marker"]} />
                        }
                        key="pickup"
                        tt="capitalize"
                        sx={{ fontSize: "14px", fontWeight: 500 }}
                      >
                        <p className="clr-grey txt-12 txtw-5">
                          {address.start}
                        </p>
                      </Timeline.Item>
                      <Timeline.Item
                        title="Drop-Off"
                        bullet={<div className={styles["end-bullet-marker"]} />}
                        key="drop-off"
                        sx={{ fontSize: "14px", fontWeight: 500 }}
                        tt="capitalize"
                      >
                        <p className="clr-grey txt-12 txtw-5">{address.end}</p>
                      </Timeline.Item>
                    </Timeline>
                  )}
                  <div className="mt-20">
                    {distanceTraveled && (
                      <div className="txt-14 txtw-5 flex justify-stretch">
                        <span>Total Distance</span>
                        <span className="clr-grey">{distanceTraveled} km</span>
                      </div>
                    )}
                    {createdAt && (
                      <div className="txt-14 txtw-5 flex justify-stretch">
                        <span>Booked At</span>
                        <span className="clr-grey">{`${getDisplayDate(
                          createdAt
                        )}, ${getDisplayTime(createdAt)} `}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* {mappedRideStatus !== "cancelled" && ( */}

              <div>
                <DetailsCard
                  detailsType="Fare Break-up"
                  isInnerCard={false}
                  width="40vw"
                  // height="45vh"
                >
                  <div className="flex justify-stretch clr-grey-dark txt-14 txtw-5">
                    <p>Base Fare</p>
                    {!showFareBreakup(rideStatus) ? (
                      "-"
                    ) : (
                      <p>
                        {currency || ""} {fareBreakup?.baseFare?.value || "-"}
                      </p>
                    )}
                  </div>
                  <div className="flex justify-stretch clr-grey-dark txt-14 txtw-5">
                    <p>Distance Fare</p>
                    {!showFareBreakup(rideStatus) ? (
                      "-"
                    ) : (
                      <p>
                        {currency || ""}{" "}
                        {fareBreakup?.distanceFare?.value || "-"}
                      </p>
                    )}
                  </div>
                  <div className="flex justify-stretch bd-top mt-10 pt-10 clr-black txtw-6">
                    <p>Total Pay</p>
                    {!showFareBreakup(rideStatus) ? (
                      "-"
                    ) : (
                      <p>
                        {currency || ""} {ridePay || "-"}
                      </p>
                    )}
                  </div>

                  {/* {mappedRideStatus !== "ongoing" && ( */}
                  <div className="flex justify-stretch mt-70 align-center">
                    <span>Payment Method:</span>
                    {!showFareBreakup(rideStatus) ? (
                      "-"
                    ) : (
                      <>{paymentMode?.toUpperCase() || "-"}</>
                    )}
                    {!showFareBreakup(rideStatus) ? (
                      "-"
                    ) : (
                      <span>
                        {paymentMode === "Cash" ? (
                          <Visa />
                        ) : paymentMode === "UPI" ? (
                          <PhonePe />
                        ) : (
                          ""
                        )}
                      </span>
                    )}
                  </div>
                  {/* )} */}
                </DetailsCard>
              </div>
              {/* )} */}
            </div>

            <div className="mb-40 mt-20">
              {supportTicketsData?.length > 0 && (
                <SupportTable supportTicketsData={supportTicketsData} />
              )}
            </div>
          </div>
        ) : (
          <EmptyPage img={NoOrdersSVG} item="Ride Details" />
        )}
      </main>
    </div>
  )
}
